import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { newParameter } from "./Parameter";
import { isArray } from "lodash";

const ServiceParameterTable = ({ serviceParams, params, setParams }) => {
  const { t } = useTranslation();

  // Helper function to get a matched parameter value
  const getMatchedParam = (serviceParamId) => {
    const matchedParam = params.find(
      (param) => param.dama_service_param_id === serviceParamId
    );

    if (matchedParam) {
      return matchedParam;
    } else {
      return {
        value: "",
        external_data_param_id: `new_${Math.floor(Math.random() * 1000)}`,
      };
    }
  };

  const handleChangeValue = (e, serviceParamId) => {
    setParams((prevParams) =>
      prevParams.map((param) =>
        param.dama_service_param_id === serviceParamId
          ? { ...param, value: e.target.value }
          : param
      )
    );
  };

  const handlePushToFlowChange = (e, serviceParamId) => {
    setParams((prevParams) =>
      prevParams.map((param) =>
        param.dama_service_param_id === serviceParamId
          ? { ...param, push_to_flow: e.target.checked ? 1 : 0 }
          : param
      )
    );
  };

  return (
    <>
      <div className="mt-5">
        <h5>{t("parameters")}</h5>
      </div>
      <div className="border-radius-4 table-responsive">
        <table className="table data-source-table">
          <thead className="bg-transparent border-0">
            <tr>
              <th>{t("parameter_name")}</th>
              <th>{t("value")}</th>
              <th>{t("required")}</th>
              <th>{t("flow")}</th>
            </tr>
          </thead>
          <tbody>
            {isArray(serviceParams) &&
              serviceParams.map((serviceParam) => {
                const matchedParam =
                  getMatchedParam(serviceParam.dama_service_param_id) ||
                  newParameter;

                return (
                  <tr key={serviceParam.dama_service_param_id}>
                    <td>
                      <input
                        type="text"
                        className="form-control-plaintext"
                        value={serviceParam.title}
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm form-control-plaintext"
                        value={matchedParam.value || ""}
                        name={`params[${matchedParam.external_data_param_id}][value]`}
                        onChange={(e) =>
                          handleChangeValue(
                            e,
                            serviceParam.dama_service_param_id
                          )
                        }
                      />
                    </td>
                    <td>
                      {serviceParam.is_required ? (
                        <span className="badge badge-danger">
                          {t("required")}
                        </span>
                      ) : (
                        <span className="badge badge-secondary">
                          {t("optional")}
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`push_to_flow_${serviceParam.dama_service_param_id}`}
                          checked={matchedParam.push_to_flow || 0}
                          name={
                            !serviceParam.isDefault
                              ? `params[${matchedParam.external_data_param_id}][push_to_flow]`
                              : null
                          }
                          onChange={(e) =>
                            handlePushToFlowChange(
                              e,
                              serviceParam.dama_service_param_id
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`push_to_flow_${serviceParam.dama_service_param_id}`}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

ServiceParameterTable.propTypes = {
  serviceParams: PropTypes.array.isRequired,
  params: PropTypes.array.isRequired,
  setParams: PropTypes.func.isRequired,
};

export default ServiceParameterTable;
