import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVectorsRequest,
  getVectorsExtraRequest,
} from "redux/vectors/action";
import { getLoginRequest } from "redux/auth/action";
import { MainContext } from "context/contexts";
import PropTypes from "prop-types";
import usePrevious from "utility/hooks/usePrevious";
import Header from "components/layouts/Header";
import Navbar from "components/layouts/Navbar";
import Login from "components/modals/Login";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { usePrompt } from "utility/hooks/usePrompt";
import { useTranslation } from "react-i18next";
import { dbPromise } from "indexedDB";
import AIDDSidebar from "./AIDDSidebar";
import { use } from "react-dom-factories";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    vectors,
    vectorsExtra,
    isGetVectorsSuccess,
    isCreatedVectorSuccess,
    isGetVectorsExtraSuccess,
    isGetVectorsExtraFailure,
  } = useSelector((state) => state.vectors);
  const {
    user,
    isGetLoginSuccess,
    isGetLoginFailure,
    isGetAuthUserSuccess,
    isSignUpSuccess,
  } = useSelector((state) => state.auth);
  const prevIsGetVectorsSuccess = usePrevious(isGetVectorsSuccess);
  const prevIsGetVectorsExtraSuccess = usePrevious(isGetVectorsExtraSuccess);

  const prevIsGetLoginFailure = usePrevious(isGetLoginFailure);
  const prevIsGetAuthUserSuccess = usePrevious(isGetAuthUserSuccess);

  const expired = JSON.parse(localStorage.getItem("expired"));
  const localSidebarOpen = JSON.parse(localStorage.getItem("sidebar_open"));
  const token = localStorage.getItem("token");

  const [isSideBarOpen, setIsSideBarOpen] = useState(localSidebarOpen);
  const [vectorsData, setVectorsData] = useState([]);
  const [autoSuggestData, setAutoSuggestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAIDDOpen, setIsAIDDOpen] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState(300); // Initial width of the sidebar

  usePrompt(t("message"), isEdited, setIsEdited);

  useEffect(() => {
    return () => {
      localStorage.removeItem("copiedStep");
      localStorage.removeItem("copiedWorkflow");
    };
  }, []);

  useEffect(() => {
    dispatch(getVectorsRequest());
    dispatch(getVectorsExtraRequest());
  }, [isCreatedVectorSuccess]);

  useEffect(() => {
    if (isGetAuthUserSuccess && prevIsGetAuthUserSuccess === false) {
      localStorage.setItem("name", user?.full_name);
      localStorage.setItem("email", user?.email);
    }
  }, [isGetAuthUserSuccess]);

  useEffect(() => {
    if (isGetVectorsSuccess && prevIsGetVectorsSuccess === false) {
      setVectorsData(structuredClone(vectors));
    }
  }, [isGetVectorsSuccess]);

  useEffect(() => {
    if (isGetVectorsExtraSuccess && prevIsGetVectorsExtraSuccess === false) {
      setAutoSuggestData(structuredClone(vectorsExtra));
      storeVectorsData(vectorsExtra?.vector_attributes);
    }
  }, [isGetVectorsExtraSuccess]);

  const storeVectorsData = async (vectors) => {
    console.log("Storing vectors data", vectors);
    if (!Array.isArray(vectors)) {
      console.error("Vectors is not an array", vectors);
      return;
    }

    const db = await dbPromise;

    const tx = db.transaction("vectors", "readwrite");
    const store = tx.objectStore("vectors");
    //clean up the store
    await store.clear();

    await Promise.all(
      vectors.map((vector, index) => {
        const searchValue = (
          vector?.value ||
          vector.attribute_path ||
          ""
        ).toLowerCase();

        // Ensure each vector has a unique 'id' (use index as fallback if no unique id is present)
        const id = vector.id || `vector-${index}`;

        return store.put({
          ...vector,
          searchValue,
          id,
          value: vector.attribute_path,
        }); // Ensure 'id' is part of the stored object
      })
    );

    await tx.done;
  };

  useEffect(() => {
    if (isGetVectorsExtraSuccess && prevIsGetVectorsExtraSuccess === false) {
      if (Array.isArray(vectorsExtra)) {
        setAutoSuggestData(structuredClone(vectorsExtra));
        storeVectorsData(vectorsExtra);
      }
    }
  }, [isGetVectorsExtraSuccess]);

  useEffect(() => {
    if (!token) {
      window.location.replace("/login");
    }
  }, [token]);

  useEffect(() => {
    if (expired) {
      setLoginModalOpen(true);
    }
  }, [expired]);

  useEffect(() => {
    if (isGetLoginSuccess) {
      localStorage.removeItem("expired");
      setLoginModalOpen(false);
      if (!isSignUpSuccess) {
        window.location.reload();
      }
    }
  }, [isGetLoginSuccess]);

  useEffect(() => {
    if (isGetLoginFailure && prevIsGetLoginFailure === false) {
      toast.error(t("unable_login"), ToastOptions);
    }
  }, [isGetLoginFailure]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const onHandleLogin = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setIsLoading(true);
    dispatch(getLoginRequest(data));
  };

  return (
    <>
      <Login
        open={loginModalOpen}
        handleConfirm={onHandleLogin}
        isLoading={isLoading}
      />
      <MainContext.Provider
        value={{
          vectorsData,
          setIsLoading,
          isMobile,
          setIsEdited,
          isLoading,
          isEdited,
          autoSuggestData,
        }}
      >
        <Header isSideBarOpen={isSideBarOpen} isLoading={isLoading} />
        <div className="container-fluid">
          <div className="row">
            <Navbar
              setIsSideBarOpen={setIsSideBarOpen}
              isSideBarOpen={isSideBarOpen}
            />
            <main
              role="main"
              className={`${
                !isSideBarOpen
                  ? "main-sidebar px-0 col-md px-4 col-lg"
                  : "col-md-9 ml-sm-auto col-lg-10"
              } px-4 min-h-100vh`}
              style={isAIDDOpen ? { marginRight: `${sidebarWidth}px` } : {}}
            >
              {children}
            </main>
            {isAIDDOpen && (
              <AIDDSidebar
                sidebarWidth={sidebarWidth}
                setSidebarWidth={setSidebarWidth}
              />
            )}
          </div>
        </div>
      </MainContext.Provider>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
