import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import {
  getReleasesRequest,
  createValidationSetRequest,
} from "redux/releases/action";

const ReleaseValidation = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetReleasesSuccess,
    releases,
    validationSet,
    isCreateValidationSetSuccess,
    isCreateValidationSetError,
  } = useSelector((state) => state.releases);

  const [selectedRelease, setSelectedRelease] = useState("");
  const [testPackage, setTestPackage] = useState(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const prevIsCreateValidationSetSuccess = usePrevious(
    isCreateValidationSetSuccess
  );
  const prevIsCreateValidationSetError = usePrevious(
    isCreateValidationSetError
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getReleasesRequest());
  }, []);

  const handleReleaseChange = (event) => {
    setSelectedRelease(event.target.value);
  };

  const handleFileChange = (event) => {
    setTestPackage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!selectedRelease || !testPackage) {
      return;
    }

    setIsLoadingUpload(true);
    console.log("Requesting signed URL...");

    dispatch(
      createValidationSetRequest({
        filename: testPackage.name,
        contentType: testPackage.type,
      })
    );
  };

  const handleUpload = async (signedUrl) => {
    if (!signedUrl || !testPackage) return;

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signedUrl, true);
      xhr.setRequestHeader("Content-Type", testPackage.type);

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded / event.total) * 100
          );
          setUploadProgress(percentCompleted);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          toast.success(t("upload_success"), ToastOptions);
        } else {
          toast.error(t("upload_error"), ToastOptions);
        }
        setIsLoadingUpload(false);
        setUploadProgress(0); // Reset progress after upload
      };

      xhr.onerror = () => {
        console.error("Upload error");
        toast.error(t("upload_error"), ToastOptions);
        setIsLoadingUpload(false);
        setUploadProgress(0); // Reset progress after error
      };

      xhr.send(testPackage);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(t("upload_error"), ToastOptions);
      setIsLoadingUpload(false);
      setUploadProgress(0); // Reset progress after error
    }
  };

  useEffect(() => {
    if (isGetReleasesSuccess) {
      setIsLoading(false);
    }
  }, [isGetReleasesSuccess]);

  // Trigger upload when signed URL is successfully obtained
  useEffect(() => {
    if (
      isCreateValidationSetSuccess &&
      prevIsCreateValidationSetSuccess === false
    ) {
      handleUpload(validationSet.signed_url);
    }
  }, [validationSet, isCreateValidationSetSuccess]);

  return (
    <>
      <SubHeader title={t("release_validation")} actions="" />
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6">
            <label htmlFor="selectRelease" className="form-label">
              {t("select_release")}
            </label>
            <select
              id="selectRelease"
              className="form-select mb-3"
              onChange={handleReleaseChange}
              value={selectedRelease}
            >
              <option value="">{t("select_release")}</option>
              {isGetReleasesSuccess &&
                releases.map((release) => (
                  <option
                    key={release.workflow_release_id}
                    value={release.workflow_release_id}
                  >
                    {release.name}
                  </option>
                ))}
            </select>

            <label htmlFor="uploadFile" className="form-label">
              {t("upload_test_package")}
            </label>
            <input
              type="file"
              className="form-control mb-3"
              id="uploadFile"
              accept=".zip,.tar,.tar.gz"
              onChange={handleFileChange}
            />

            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!selectedRelease || !testPackage || isLoadingUpload}
              >
                {isLoadingUpload ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("upload")
                )}
              </button>
            </div>

            {uploadProgress > 0 && (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {`${uploadProgress}%`}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReleaseValidation;
