import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dataMarketGetServicesRequest,
  dataMarketActivateServiceRequest,
  dataMarketDeactivateMyServiceRequest,
  dataMarketGetServiceDetailsRequest,
} from "redux/dataMarket/action";
import ServiceCard from "./ServiceCard";
import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { isArray } from "lodash";
import "assets/css/dataMarketplace.scss";
import usePrevious from "utility/hooks/usePrevious";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteConfirm from "components/modals/DeleteConfirm";

const DataMarketServicesPage = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    services,
    isLoading,
    dataServiceDetails,
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
    isDataMarketDeactivateMyServiceSuccess,
    isDataMarketGetServiceDetailsSuccess,
    isDataMarketGetServicesSuccess,
    isDataMarketGetServicesError,
  } = useSelector((state) => state.dataMarket);

  const [activeService, setActiveService] = useState(null);
  const [serviceDetails, setServiceDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // State for API credentials and T&C agreement
  const [apiKey, setApiKey] = useState("");
  const [apiUsername, setApiUsername] = useState("");
  const [apiPassword, setApiPassword] = useState("");
  const [hasAgreedToTnC, setHasAgreedToTnC] = useState(false);

  const prevIsDataMarketActivateServiceSuccess = usePrevious(
    isDataMarketActivateServiceSuccess
  );
  const prevIsDataMarketActivateServiceError = usePrevious(
    isDataMarketActivateServiceError
  );
  const prevIsDataMarketDeactivateMyServiceSuccess = usePrevious(
    isDataMarketDeactivateMyServiceSuccess
  );
  const prevIsDataMarketGetServiceDetailsSuccess = usePrevious(
    isDataMarketGetServiceDetailsSuccess
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [serviceToDeactivate, setServiceToDeactivate] = useState(null);

  useEffect(() => {
    //set title
    document.title = `${t("data_services_hub")} - Decisimo`;
    setIsLoading(true);
    dispatch(dataMarketGetServicesRequest());
  }, [dispatch, setIsLoading]);

  //finish loaidng
  useEffect(() => {
    if (isDataMarketGetServicesSuccess || isDataMarketGetServicesError) {
      setIsLoading(false);
    }
  }, [isDataMarketGetServicesSuccess, isDataMarketGetServicesError]);

  useEffect(() => {
    if (
      isDataMarketActivateServiceSuccess &&
      !prevIsDataMarketActivateServiceSuccess
    ) {
      toast.success(t("service_activated_successfully"), ToastOptions);
      setIsModalOpen(false); // Close activation modal on success
      setIsLoading(false);
    }
    if (
      isDataMarketActivateServiceError &&
      !prevIsDataMarketActivateServiceError
    ) {
      toast.error(t("service_activation_failed"), ToastOptions);
      setIsLoading(false);
    }
    if (
      isDataMarketDeactivateMyServiceSuccess &&
      !prevIsDataMarketDeactivateMyServiceSuccess
    ) {
      toast.success(t("service_deactivated_successfully"), ToastOptions);
      setShowEditModal(false); // Close edit modal on success
      setIsLoading(false);
    }
  }, [
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
    isDataMarketDeactivateMyServiceSuccess,
    t,
    prevIsDataMarketActivateServiceSuccess,
    prevIsDataMarketActivateServiceError,
    prevIsDataMarketDeactivateMyServiceSuccess,
  ]);

  useEffect(() => {
    if (
      isDataMarketGetServiceDetailsSuccess &&
      !prevIsDataMarketGetServiceDetailsSuccess
    ) {
      setServiceDetails(dataServiceDetails);
      setShowEditModal(true); // Open edit modal after fetching details
      setIsLoading(false);
    }
  }, [
    isDataMarketGetServiceDetailsSuccess,
    prevIsDataMarketGetServiceDetailsSuccess,
    dataServiceDetails,
  ]);

  const handleActivate = () => {
    if (activeService) {
      setIsLoading(true);
      dispatch(
        dataMarketActivateServiceRequest({
          dama_service_id: activeService.dama_service_id,
          custom_api_key: apiKey,
          custom_api_user: apiUsername,
          custom_api_password: apiPassword,
        })
      );
    }
  };

  const handleGetServiceDetails = (serviceId) => {
    setIsLoading(true);
    dispatch(
      dataMarketGetServiceDetailsRequest({ dama_service_id: serviceId })
    );
    setActiveService({ dama_service_id: serviceId }); // Store active service ID
  };

  const handleDeactivate = (serviceId) => {
    setServiceToDeactivate(serviceId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDeactivate = () => {
    setIsLoading(true);
    dispatch(
      dataMarketDeactivateMyServiceRequest({
        dama_service_id: serviceToDeactivate,
      })
    );
    setIsDeleteModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setShowEditModal(false);
  };

  return (
    <>
      <SubHeader title={t("data_services_hub")} actions="" />
      <div className="data-market-services-page mt-4">
        {isLoading ? (
          <div>{t("loadingServices")}...</div>
        ) : services.length > 0 && isArray(services) ? (
          <div className="row">
            {services.map((service) => (
              <div key={service.dama_service_id} className="col-md-4 mb-4">
                <ServiceCard
                  service={service}
                  onActivate={() => {
                    setActiveService(service); // Store the service to be activated
                    setIsModalOpen(true); // Open activation modal
                    setApiKey(""); // Clear previous values
                    setApiUsername("");
                    setApiPassword("");
                    setHasAgreedToTnC(false);
                  }}
                  onDeactivate={() => handleDeactivate(service.dama_service_id)}
                  handleGetServiceDetails={() =>
                    handleGetServiceDetails(service.dama_service_id)
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <div>{t("no_services_available")}</div>
        )}
      </div>

      {/* Activation Modal */}
      <Modal size="md" show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("activate_service")}</h5>
          <button type="button" className="close" onClick={handleModalClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "password" && (
                <>
                  <Form.Group controlId="apiUsername">
                    <Form.Label>{t("api_username")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter API username")}
                      value={apiUsername}
                      onChange={(e) => setApiUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="apiPassword">
                    <Form.Label>{t("api_password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("enter_api_password")}
                      value={apiPassword}
                      onChange={(e) => setApiPassword(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "key" && (
                <Form.Group controlId="apiKey">
                  <Form.Label>{t("api_key")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("enter_api_key")}
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </Form.Group>
              )}
            {activeService?.service_type === "free" && (
              <p>
                {t("free_service_activation_message", {
                  provider: activeService.provider_title,
                })}
              </p>
            )}
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={t("i_agree_to_terms_and_conditions")}
                checked={hasAgreedToTnC}
                onChange={(e) => setHasAgreedToTnC(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleModalClose}>
            {t("Close")}
          </Button>
          <Button
            variant="outline"
            onClick={handleActivate}
            disabled={!hasAgreedToTnC}
          >
            <span>{t("Submit")}</span>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal size="lg" show={showEditModal} onHide={handleModalClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("edit_service")}</h5>
          <button type="button" className="close" onClick={handleModalClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>{t("Service Information:")}</p>
            {dataServiceDetails?.custom_api_key && (
              <p>
                <strong>{t("API Key:")}</strong>{" "}
                {dataServiceDetails.custom_api_key}
              </p>
            )}
            {dataServiceDetails?.custom_api_user && (
              <p>
                <strong>{t("API Username:")}</strong>{" "}
                {dataServiceDetails.custom_api_user}
              </p>
            )}
            {dataServiceDetails?.custom_api_password && (
              <p>
                <strong>{t("API Password:")}</strong> {"******"}
              </p>
            )}
            {serviceDetails?.external_data && (
              <>
                <h3 className="mt-4">{t("Service Details")}</h3>
                <ul>
                  {serviceDetails.external_data.map((data) => (
                    <li key={data.external_data_id}>
                      <Link
                        to={`/external-data-source/${data.external_data_id}`}
                      >
                        {data.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="outline"
            onClick={() => handleDeactivate(activeService.dama_service_id)}
          >
            {t("deactivate_service")}
          </Button>
          <Button variant="outline" onClick={handleModalClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfirm
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleConfirmDeactivate}
        title={t("deactivate_service")}
        message={
          isArray(dataServiceDetails?.external_data) &&
          dataServiceDetails?.external_data.length > 0
            ? t("deactivate_service_message_with_external_data", {
                title: dataServiceDetails?.title || "",
              })
            : t("are_you_sure_deactivate_service", {
                title: dataServiceDetails?.title || "",
              })
        }
        confirm_button={t("deactivate")}
      />
    </>
  );
};

export default DataMarketServicesPage;
