import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ServiceCard.css";

const ServiceCard = ({
  service,
  onActivate,
  onDeactivate,
  handleGetServiceDetails,
}) => {
  const { t } = useTranslation();

  const handleActivateClick = () => {
    onActivate(); // Call the parent's onActivate function
  };

  const handleEditClick = () => {
    handleGetServiceDetails(service.dama_service_id); // Call the parent's handleGetServiceDetails function
  };

  const serviceTypeBadgeColor = (type) => {
    switch (type) {
      case "managed":
        return "bg-blue-500";
      case "free":
        return "bg-green-500";
      case "byok":
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  const formatPrice = (price) => {
    return price === 0 || price === "Free" || price === undefined
      ? t("Free")
      : `${price} $/call`;
  };

  return (
    <div className="card service-card">
      <div className="card-header">
        <h5 className="card-title">{service.title}</h5>
        <div>
          <span
            className={`badge ${serviceTypeBadgeColor(service.service_type)}`}
          >
            {t(service.service_type)}
          </span>
          {service.dama_service_tenant_id &&
          service?.tenant_service_status === "a" ? (
            <>
              <span className="badge badge-success ml-2">{t("activated")}</span>
              <button className="btn ml-2" onClick={handleEditClick}>
                {t("edit")}
              </button>
            </>
          ) : (
            <button
              onClick={handleActivateClick}
              className="btn btn-outline-primary ml-2"
            >
              {t("activate")}
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        <p className="card-text">{service.description}</p>
        <p className="service-price">{formatPrice(service.price)}</p>
        <p className="service-provider">
          {t("by")}{" "}
          <a
            href={service.providerUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {service.provider_title}
          </a>
        </p>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    dama_service_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    dama_service_tenant_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    external_data: PropTypes.array,
    isActive: PropTypes.bool,
    secret_type: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    providerName: PropTypes.string,
    provider_title: PropTypes.string,
    providerUrl: PropTypes.string,
    service_type: PropTypes.string,
    tenant_service_status: PropTypes.string,
  }).isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  handleGetServiceDetails: PropTypes.func.isRequired,
};

export default ServiceCard;
